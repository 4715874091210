<template>
    <div id="dataGet">
        <HeaderPart></HeaderPart>
        <router-view class="dataGetCtn"></router-view>
    </div>
</template>

<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import HeaderPart from "./comp/HeaderPart.vue";
export default {
    name: "dataGet",
    components: {
        HeaderPart,
    },
    data() {
        return {};
    },
    computed: {},
    created() {},
    mounted() {},
    methods: {
        ...mapMutations({}),
    },
};
</script>

<style lang="scss" scoped>
#dataGet {
    height: 100%;
    width: 100%;
    .dataGetCtn {
        height: calc(100% - 120px);
    }
}
</style>
