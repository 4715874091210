<template>
    <!-- 顶部标题 -->
    <div class="headerPart">
        <img src="@image/icon/logo.png" class="logo" />
        <div class="rightPart">
            <div class="topPart flex">
                <div class="time">
                    {{ time }}
                </div>
                <div class="role">
                    <span style="margin-right:5px;">{{ loginPeo.role }}</span>
                    <span>{{ loginPeo.realName }}</span>
                </div>
                <div class="time">欢迎您！</div>
            </div>
            <div class="actionPart">
                <SnActionBtn
                    :showNameList="['主页', '退出']"
                    :srcState="false"
                ></SnActionBtn>
            </div>
        </div>

        <!-- 路由切换 -->
        <div class="routePart flex">
            <div
                v-for="(item, index) in routerList"
                :key="index"
                class="routeItem pointer"
                @click="goRoute(item)"
            >
                {{ item.name }}
            </div>
        </div>
    </div>
</template>
<script>
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
    name: "",
    components: {},
    data() {
        return {
            time: "",
            routerList: [
                // {
                //   name: "数据填报",
                //   url: "/data_get",
                // },
                // {
                //   name: "运维管理",
                //   url: "/usermanage",
                // },
            ],
            loginPeo: {
                role: localStorage.JM_ROLENAME,
                realName: localStorage.userRealName,
            },
        };
    },
    computed: {
        ...mapGetters({}),
    },
    watch: {},
    mounted() {
        setInterval(() => {
            let str = this.common.getTimeYmdhms();
            this.time =
                str.slice(0, 4) +
                "年" +
                str.slice(5, 7) +
                "月" +
                str.slice(8, 10) +
                "日" +
                str.slice(11);
        }, 1000);
    },
    methods: {
        ...mapMutations({}),
        ...mapActions([]),
        goRoute(item) {
            this.$router.push({
                path: item.url,
            });
        },
    },
};
</script>
<style scoped lang="scss">
.headerPart {
    width: 100%;
    height: 78px;
    background: url("~@image/icon/header.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    .routePart {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        font-size: 18px;
        .routeItem {
            margin-right: 50px;
        }
    }
    .logo {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .rightPart {
        position: absolute;
        right: 0;
        height: 100%;
        .topPart {
            color: #f5fafa;
            margin-top: 10px;
            .time {
            }
            .role {
                margin: 0 10px;
            }
        }
        .actionPart {
            position: absolute;
            right: 10px;
            bottom: 0px;
            width: 103px;
        }
    }
}
</style>
